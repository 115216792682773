import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Link from './Link';
import SlashList from './SlashList';
import Arrow from './Arrow';

import css from './Teaser.module.css';

const propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  url: PropTypes.string.isRequired,
};

const defaultProps = {

};

function Teaser({
  title, text, categories, url,
}) {
  const [isHover, setIsHover] = useState(false);
  return (
    <article className={`ft-component ${css.teaser} ft-grid ft-flex ft-flex--col`}>
      <h2 className={`${css.teaser__title} ft-margin-reset--y`}>
        <Link
          to={url}
          className="ft-text-decoration--none"
          onMouseOver={() => setIsHover(true)}
          onFocus={() => setIsHover(true)}
          onMouseOut={() => setIsHover(false)}
          onBlur={() => setIsHover(false)}
        >
          {title}
        </Link>
      </h2>
      <div className={css.teaser__text}>
        <p className="ft-margin-reset--y">
          {text}
          &#8196;
        </p>
        <Link className={`${css.teaser__readMoreLink} ft-text-decoration--none`} to={url}>
          <span className="ft-visually-hidden">Weiterlesen</span>
          <Arrow isExpanded={isHover} />
        </Link>
      </div>
      {categories && categories.length > 0 && (
        <footer className={`${css.teaser__meta} ft-font--sm ft-font--bold`}>
          <h3 className="ft-visually-hidden">Kategorien:</h3>
          <SlashList categories={categories} linkClassName="ft-text-decoration--none" />
        </footer>
      )}
    </article>
  );
}

Teaser.propTypes = propTypes;
Teaser.defaultProps = defaultProps;

export default Teaser;
