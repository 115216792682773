import React from 'react';
import PropTypes from 'prop-types';

import ArrowRightPart2 from '../images/arrow-right-part-2.inline.svg';

import css from './Arrow.module.css';

const propTypes = {
  isExpanded: PropTypes.bool,
};

const defaultProps = {
  isExpanded: false,
};

function Arrow({ isExpanded }) {
  return (
    <span className={`${css.arrow} ft-svg-path-currentColor`} aria-hidden="true" data-is-expanded={isExpanded}>
      <span className={`${css.arrow__part} ${css.arrow__part1}`} />
      <span className={`${css.arrow__part} ${css.arrow__part2}`}>
        <ArrowRightPart2 />
      </span>
    </span>
  );
}

Arrow.propTypes = propTypes;
Arrow.defaultProps = defaultProps;

export default Arrow;
