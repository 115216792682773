import React from 'react';
import PropTypes from 'prop-types';

import Link from './Link';
import Arrow from './Arrow';

const propTypes = {
  currentPage: PropTypes.number.isRequired,
  numPages: PropTypes.number.isRequired,
  pagePathHome: PropTypes.string.isRequired,
  pagePathPrefix: PropTypes.string.isRequired,
  backLabel: PropTypes.string,
};

const defaultProps = {
  backLabel: 'Zurück zur Startseite',
};

function Pagination({
  currentPage, numPages, pagePathHome, pagePathPrefix, backLabel,
}) {
  if (numPages === 1) {
    return null;
  }

  return (
    <nav className="ft-component ft-grid" role="navigation">
      {currentPage === numPages ? (
        <Link className="ft-text-decoration--none" to={pagePathHome}>
          {backLabel}
        </Link>
      ) : (
        <Link className="ft-text-decoration--none" to={`${pagePathHome}${pagePathPrefix}${currentPage + 1}/`}>
          <span>Nächste Seite&#8196;</span>
          <Arrow />
        </Link>
      )}
    </nav>
  );
}

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;

export default Pagination;
